import React from 'react';
import styles from './version.module.css';

class Version extends React.Component {
  render () {
    const {version, date, description, type, image} = this.props;
    const split = version.split("@");
    let result = version;

    if (split.length > 1) {
      result = split[0] + "<span>@" + split[1] + "</span>";
    }
    return (
      <div className={styles.container}>
        <div className={styles.head}>
          <h2 dangerouslySetInnerHTML={{ __html: result }}/>{type && <span className={[styles.system, styles[type]].join(' ')}>{type}</span>} <h3>{date}</h3>
        </div>
        <div className={styles.description}>
            {image && <div className={styles.image}><span>{type} System image</span></div> }
            <div dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
      </div>
    )
  }
}


export default Version;
