import React, { Component } from 'react';
import PropTypes from 'prop-types';
import s from './systemsSwitcher.module.css';

class SystemsSwitcher extends Component {
  static propTypes = {
    selected: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render () {
    const { types, selected, onChange } = this.props;
    return (
      <div className={s.root}>
        <div className={s.types}>
          {types.map(type => {
            if (selected === type) {
              return (
                <div
                  className={s.itemSelected}
                  key={type}
                  onClick={() => { onChange(type) }}
                  role="presentation"
                >
                  {type}
                </div>
              );
            }

            return (
              <div
                className={s.item}
                key={type}
                onClick={() => { onChange(type) }}
                role="presentation"
              >
                {type}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}


export default SystemsSwitcher;
