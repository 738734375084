import React from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import ReactPaginate from 'react-paginate';
import { animateScroll as scroll } from 'react-scroll';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import Layout from 'components/layout';
import styles from 'pages/pages.module.css';
import Bottom from 'components/Bottom/Bottom';

import Version from 'components/changelog/Version';
import SystemsSwitcher from 'components/changelog/SystemsSwitcher';
import Slimdown from 'helpers/slimdown.js';
import cx from 'classnames';

import pageStyles from './changelog.module.css';

const CHANGELOG = 'https://api2.hiveos.farm/api/v2/hive/versions';
const ALL_TYPE = 'All';
const ITEM_PER_PAGE = 10;
const MOBILE_BREAKPOINT = 450;

class Changelog extends React.Component {

  constructor() {
    super()
    this.state = {
      changes: [],
      changesPerPage: [],
      filteredChanges: [],
      systemType: ALL_TYPE,
      currentPage: 1,
      valueGoTo: '',
    };
    this.isMobile = false;
    if (typeof window !== 'undefined') {
      const width = Math.min(
        window.innerWidth,
        _get(document, 'documentElement.clientWidth', 1900),
      );
      if (width <= MOBILE_BREAKPOINT) {
        this.isMobile = true;
      }
    }
  }

  componentDidMount() {
    fetch(CHANGELOG)
      .then(response => response.json())
      .then(result => {
        let changelog = result.data

        const slimdown = new Slimdown()
        const changes = []

        changelog.map(item => {
          let description = slimdown.render(item.description)
          changes.push({
            version: item.version,
            date: item.date,
            description: description,
            type: item.system_type,
            image: item.image
          })
          return item
        })
        
        const { search, pathname } = this.props.location;
        const queryParams = search && queryString.parse(search);
        const types =  this.getSystemTypes(changes);
        const systemType = queryParams.type && types.includes(queryParams.type) ? queryParams.type : ALL_TYPE;
        const filteredChanges = systemType === ALL_TYPE ? changes : changes.filter(({ type }) => type === systemType);
        const countPage = Math.ceil(filteredChanges.length / ITEM_PER_PAGE);
        const page = queryParams.page && queryParams.page <= countPage ? queryParams.page : 1;
        const offset = (page - 1) * ITEM_PER_PAGE;
        const url = `${pathname}?type=${systemType}&page=${page}`;
        
        navigate(url);
        
        this.setState({
          changes: changes,
          filteredChanges: filteredChanges,
          changesPerPage: filteredChanges.slice(offset, offset + ITEM_PER_PAGE),
          types: types,
          currentPage: page,
          systemType: systemType,
        })
      })
  }

  getSystemTypes = (changes) => {
    const systems = [ALL_TYPE];

    if (Array.isArray(changes)) {
      changes.forEach(change => {
        if (!systems.includes(change.type)) {
          systems.push(change.type);
        }
      });
    }

    return systems;
  };

  handleChange = async (chosenType) => {
    const { types, changes } = this.state;

    if (types.includes(chosenType)) {
      const filteredChanges = changes.filter(({ type }) => chosenType === ALL_TYPE ? true : type === chosenType);
      await this.setState({ systemType: chosenType, filteredChanges: filteredChanges });
    } else {
      await this.setState({ systemType: ALL_TYPE, filteredChanges: changes });
    }
    this.handleChangePage({ selected: 0 });
  }
  
  handleChangePage = (data) => {
    const { filteredChanges, systemType } = this.state;
    const { pathname } = this.props.location;
    
    const selected = data.selected;
    const offset = selected * ITEM_PER_PAGE;
    const url = `${pathname}?type=${systemType}&page=${selected + 1}`;

    this.setState({ 
      currentPage: selected + 1, 
      changesPerPage: filteredChanges.slice(offset, offset + ITEM_PER_PAGE),
    });

    navigate(url);
    scroll.scrollToTop();
  }

  onChangeGoToPage = e => {
    const value = e.target.value || '';
    const pageCount = Math.ceil(this.state.filteredChanges.length / ITEM_PER_PAGE);
    if (value > pageCount || value === '0') {
      return;
    }
    this.setState({ valueGoTo: value.replace(/[^0-9]/, '') });
  };

  handleSubmit = e => {
    const { valueGoTo } = this.state;
    e.preventDefault();
    valueGoTo && this.handleChangePage({ selected: valueGoTo - 1});
    this.setState({ valueGoTo: '' });
  };

  render () {
    const { changes, systemType, types, changesPerPage, filteredChanges, currentPage } = this.state;
    
    const pageCount = Math.ceil(filteredChanges.length / ITEM_PER_PAGE);
    const prevClassName = currentPage === 1 ? pageStyles.hidden : pageStyles.previous;
    const nextClassName = currentPage === pageCount ? pageStyles.hidden : pageStyles.next;
    
    return (
      <Layout page="changelog" lang={_get(this.props, 'pageContext.lang', 'en')}>
        <section className={cx(styles.content, styles.heroBlock)}>
          <div className={styles.wrapper}>
            <h1 className={cx(styles.textCenter, styles.accent)}>
              <FormattedMessage id="Changelog.title" defaultMessage="Changelog" />
            </h1>
            <h2 className={cx(styles.textCenter, styles.subheader)}>
              <FormattedMessage id="Changelog.caption" defaultMessage="Keep track of changes and upgrades to the Hive OS" />
            </h2>
          </div>
        </section>
        <div>
          <section className={cx(styles.content, pageStyles.changelog)}>
            { changes.length > 0 &&
              <SystemsSwitcher
                selected={systemType}
                types={types}
                onChange={this.handleChange}
              />
            }
            <div className={styles.wrapper}>
              { changesPerPage.length > 0 && changesPerPage.map((item, index) => 
                <Version {...item} key={item.version+index} />
                )}
              {pageCount > 1 && (
                <form onSubmit={this.handleSubmit} className={cx(pageStyles.root, { [pageStyles.mobileControls]: this.isMobile })}>
                  <div className={pageStyles.goToBlock}>
                    <div><FormattedMessage id="GoTo" defaultMessage="Go to" /></div>
                    <input
                      className={pageStyles.inputPage}
                      value={this.state.valueGoTo}
                      onChange={this.onChangeGoToPage} />
                    <div><FormattedMessage id="page" defaultMessage="page" /></div>
                  </div>
                  <div className={pageStyles.paginateWrap}>
                    <ReactPaginate
                      previousLabel={this.isMobile ? '‹' : <FormattedMessage id="Previous" defaultMessage="Previous" />}
                      nextLabel={this.isMobile ? '›' : <FormattedMessage id="Next" defaultMessage="Next" />}
                      breakLabel="..."
                      pageCount={pageCount}
                      marginPagesDisplayed={this.isMobile ? 1 : 3}
                      pageRangeDisplayed={3}
                      initialPage={0}
                      forcePage={currentPage - 1}
                      disableInitialCallback
                      onPageChange={this.handleChangePage}
                      subContainerClassName=""
                      breakClassName={pageStyles.breakMe}
                      containerClassName={pageStyles.pagination}
                      previousClassName={prevClassName}
                      nextClassName={nextClassName}
                      activeClassName={pageStyles.activePage}
                      pageClassName={pageStyles.pageItem}
                    />
                  </div>
                </form>
              )}
            </div>
          </section>
          <Bottom/>
        </div>
      </Layout>
    )
  }
}

export default Changelog
